<template>
  <div :style="cssVars">
    <v-layout v-resize="onResize">
      <v-main :style="'color:' + textColour + '; background-color:' +  backgroundColour">
        <v-img :class="{
            'v-image--sm': this.$vuetify.breakpoint.smAndDown,
            'v-image--md': this.$vuetify.breakpoint.mdAndDown
          }"
          :src="backgroundImage" min-height="100%">
        <Toolbar :boxOffice="boxOffice" :event="event"/>
        <div align ="right">
          <Button :style="cssVars" v-if="boxOffice" small class="mt-2 mr-2" @click="boxOfficeLink()">Browse Other Events</Button>
          <checkoutButton @click="checkout=true"/>
        </div>
        <div>
          <v-responsive :style="styles" min-height="85vh" class="fill-height justify-center">
            <div style="display: flex; flex-wrap: wrap; justify-content: center; font-family:Montserrat, sans-serif">
              <div v-if="queued" style="max-width:600px">
                <cld-image class="mx-auto" style="display:table" :publicId="logoImage" />
                <queue @queued="queueUpdate" class="mt-5"/>
              </div>
              <div v-else-if="boxOffice && event">
              <div style="max-width:600px" class="mx-5">
                <cld-image class="mx-auto" style="display:table" :publicId="logoImage" />
                <h1 class="text-center mb-5 mt-5">{{event.name}}</h1>
                <div style="display:table" class="mx-auto">
                  <div v-if="eventTimes && eventTimes.length > 5">
                    <h3 v-if="firstEventTime || lastEventTime">
                      <v-icon class="mt-n1" :color="textColour">mdi-clock-time-four</v-icon>
                      <span v-if="utils.sameDay(firstEventTime.start, lastEventTime.end)">
                        {{utils.dateToNiceDay(firstEventTime.start)}}
                        {{utils.dateToNiceTime(firstEventTime.start)}} to {{utils.dateToNiceTime(lastEventTime.end)}}
                      </span>
                      <span v-else>
                        {{utils.dateToNiceString(firstEventTime.start)}} to {{utils.dateToNiceString(lastEventTime.end)}}
                      </span>
                    </h3>
                  </div>
                  <div v-else>
                    <div v-for="(eventTime, i) in eventTimes" :key="i">
                      <h3>
                        <v-icon class="mt-n1" :color="textColour">mdi-clock-time-four</v-icon>
                        <span v-if="utils.sameDay(eventTime.start, eventTime.end)">
                          {{utils.dateToNiceDay(eventTime.start)}}
                          {{utils.dateToNiceTime(eventTime.start)}} to {{utils.dateToNiceTime(eventTime.end)}}
                        </span>
                        <span v-else>
                          {{utils.dateToNiceString(eventTime.start)}} to {{utils.dateToNiceString(eventTime.end)}}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <h3 v-if="event.location" style="display:table" class="mt-1 mx-auto">
                  <v-icon class="mt-n1" :color="textColour">mdi-map-marker</v-icon> {{event.location}}
                </h3>
                <!--h3 style="display:table" class="mt-1 mx-auto" v-if="ticketTypes">
                  <span v-if="lowestPrice">{{utils.priceDisplay(lowestPrice)}}</span>
                  <span v-if="lowestPrice != highestPrice"> to {{utils.priceDisplay(highestPrice)}}</span>
                </h3-->
                <div class="mt-2">
                  <cld-image class="mx-auto" style="display:table" :publicId="event.eventImage" :width="maxImageWidth" crop="limit"/> 
                  <span class="ma-2" v-html="event.description"/>
                </div>
              </div>
              <div style="max-width:500px">
                <div>
                  <div v-if="eventTimes && eventTimes.length < 1">
                    <v-card outlined tile class="mt-0">
                      <v-card-title class="mb-1">Sorry, you can't buy tickets for this event as there are no times available.</v-card-title>
                    </v-card>
                  </div>
                  <div v-else-if="!eventTime">
                    <v-card outlined tile class="mt-0">
                      <v-card-title>Choose Date for {{event.name}}</v-card-title>
                      <v-card-text>
                        <v-row class="d-flex justify-center mt-5" v-for="(eventTime, i) in eventTimes" :key="i">
                          <v-col cols="8">
                            <div class="d-flex" v-if="utils.sameDay(eventTime.start, eventTime.end)">
                              <div>{{utils.dateToNiceDay(eventTime.start)}}</div>
                              <div class="ml-2">{{utils.dateToNiceTime(eventTime.start)}} to {{utils.dateToNiceTime(eventTime.end)}}</div>
                            </div>
                            <div v-else>
                              {{utils.dateToNiceString(eventTime.start)}} <br>to {{utils.dateToNiceString(eventTime.end)}}
                            </div>
                          </v-col>
                          <v-col cols="4">
                            <Button v-if="availableTickets(null, eventTime) > 0" @click="showTicketsDialog(eventTime)">
                              <span v-if="$vuetify.breakpoint.smAndUp">Tickets</span>
                              <span v-else>Buy</span>
                            </Button>
                            <Button v-else disabled >Sold Out</Button>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div v-else>
                    <div v-if="eventTimes && eventTimes.length > 1" class="mb-5 d-flex">
                      <v-spacer/>
                      <Button @click='eventTime = null'>Browse other Dates / Times</Button>
                      <v-spacer/>
                    </div>
                    <v-card outlined tile class="mx-5 mt-0" v-for="ticketType in ticketTypes" :key="ticketType.id">
                      <v-card-title>{{ticketType.name}}</v-card-title>
                      <v-card-text>
                        <event-time-display v-model="eventTime"/>
                        <v-row>
                          <v-col cols="6" class="mt-5">
                            <div style="text-align: left">
                              <div v-if="ticketType.description">{{ticketType.description}} </div>              
                              <div v-if="ticketType.image" @click="showProduct(ticketType)"             >
                                <cld-image class="mx-auto" style="cursor: pointer" :publicId="ticketType.image" :width="150" crop="limit"/> 
                              </div> 
                              <div>
                                {{utils.priceDisplay(ticketType.price)}} 
                                <span v-if="ticketType.bookingFee"> + {{utils.priceDisplay(ticketType.bookingFee)}} booking fee</span>
                              </div>
                              <div v-if="ticketType.onSaleFrom && utils.dateNow() < ticketType.onSaleFrom">Tickets will go on sale at {{utils.dateToShortString(ticketType.onSaleFrom)}}</div>
                              <div v-else-if="ticketType.onSaleTo && utils.dateNow() > ticketType.onSaleTo">Sorry, ticket sales ended at  {{utils.dateToShortString(ticketType.onSaleTo)}}</div>
                              <div v-else-if="availableTickets(ticketType, eventTime) < 1">Sorry, no tickets left</div>
                              <div v-else-if="ticketType.showAvailability">{{availableTickets(ticketType, eventTime)}} tickets available</div>
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <div style="text-align: right" v-if="boxOffice.isUserOwner || ((!ticketType.onSaleFrom || utils.dateNow() > ticketType.onSaleFrom) && 
                                      (!ticketType.onSaleTo || utils.dateNow() < ticketType.onSaleTo) && (availableTickets(ticketType, eventTime) > 0))">
                              <v-select color="textColor" class="ml-auto" value=1 style="width:70px;" v-model="requested[ticketType.id]" :items="numTickets(ticketType, eventTime)">
                                <template v-slot:selection="{ item }">
                                  <span class="display-2">{{item}}</span>
                                </template>
                              </v-select>
                              <Button class="mt-n2" @click="selectTicket(ticketType, requested[ticketType.id])"><v-icon x-small class="mr-2">mdi-cart</v-icon>Add</Button>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                  <v-card outlined tile class="mx-5 mt-0" v-for="product in products" :key="product.id">
                    <v-card-title>{{product.name}}</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6">
                          <div v-if="product.description">{{product.description}} </div> 
                          <div v-if="product.image" @click="showProduct(product)"             >
                            <cld-image class="mx-auto" style="cursor: pointer" :publicId="product.image" :width="150" crop="limit"/> 
                          </div> 
                          <div>
                            {{utils.priceDisplay(product.price)}} 
                          </div>
                          <div v-if="product.onSaleFrom && utils.dateNow() < product.onSaleFrom">Will go on sale at {{utils.dateToShortString(product.onSaleFrom)}}</div>
                          <div v-else-if="product.onSaleTo && utils.dateNow() > product.onSaleTo">Sorry, sales ended at  {{utils.dateToShortString(product.onSaleTo)}}</div>
                          <div v-else-if="product.quantity - product.sold < 1">Sorry, none left</div>
                          <div v-else-if="product.showAvailability">{{product.quantity - product.sold}} available</div>
                        </v-col>
                        <v-col cols="6">
                          <div style="text-align: right" v-if="(!product.onSaleFrom || utils.dateNow() > product.onSaleFrom) && 
                                    (!product.onSaleTo || utils.dateNow() < product.onSaleTo) && (product.quantity - product.sold > 0)">
                            <v-select class="ml-auto mt-n4" value=1 style="width:70px;" v-model="requested[product.id]" :items="numProducts(product)">
                              <template v-slot:selection="{ item }">
                                <span class="display-2">{{item}}</span>
                              </template>
                            </v-select>
                            <v-select v-if="product.options" class="ml-auto mt-n4" style="width:70px;" v-model="option[product.id]" :items="options[product.id]">
                              <template v-slot:selection="{ item }">
                                <span class="display-2">{{item}}</span>
                              </template>
                              <template v-slot:item="{ item }">
                                <span class="display-2">{{item}}</span>
                              </template>
                            </v-select>
                            <Button class="mt-n2" @click="selectProduct(product, requested[product.id], option[product.id])"><v-icon x-small class="mr-2">mdi-cart</v-icon>Add</Button>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-dialog dark v-if="product" v-show="productDialog" v-model="productDialog" persistent max-width="600px">
                    <v-card outlined tile :style="cssVars">
                      <v-card-title>{{product.name}}
                        <v-spacer></v-spacer>
                        <v-icon large @click="productDialog = false">mdi-close</v-icon>
                      </v-card-title>
                      <v-card-text>
                        <cld-image class="mx-auto" :publicId="product.image" :width="maxProductImageWidth" crop="limit"/> 
                        <div style="text-align:center">{{product.description}}</div>
                        <div style="text-align:center">{{utils.priceDisplay(product.price)}} </div>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              </div>
            </div>
          </v-responsive>
        </div>
        <div v-if="notFound">
          <v-main>
            <v-container>
            <v-row align="center" justify="center">
                <v-col class="text-center">
                  <pages-heading>404 - Not Found</pages-heading>
                  <Button v-if="boxOffice" class="mt-5" @click="boxOfficeLink()">Box Office</Button>
                </v-col>
              </v-row>
            </v-container>
          </v-main>
        </div>
        <div v-if="boxOffice" class="mt-10 text-center">
          Please checkout our other Events<br/>
          <Button v-if="boxOffice" @click="boxOfficeLink()"><span>Browse Other Events</span></Button>
        </div>
      </v-img>
      <contact :boxOffice="boxOffice" :event="event"/>
    </v-main>
  </v-layout>

  <checkout v-if="checkout" :boxOffice="boxOffice" @closed="checkout=false" @closedConfirmed="checkoutComplete()"/>

</div>
</template>
  
<script>
  import axios from "axios"
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js'
  import EventUtils from '@/services/EventUtils.js';
  import Store from '@/services/Store.js';
import Queue from './Queue.vue';

!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');

  export default {
    components: {
      PagesHeading: () => import('../pages/components/Heading'),
      EventTimeDisplay: () => import('../dashboard/components/EventTimeDisplay'),
      CheckoutButton: () => import('./CheckoutButton'),
      Checkout: () => import('./Checkout'),
      Contact: () => import('./Contact'),
      Button: () => import('./Button'),
      Queue: () => import('./Queue'),
      Toolbar: () => import('./Toolbar')
    },  

    metaInfo() {
      console.log(this.meta)
        return this.meta;
    },

    data () {
      return {
        meta:  {
          title: "Happy Tickets",
        },
        checkout: false,
        ticketsDialog: false,
        productDialog: false,
        eventTimeDialog: false,
        textColour: null,
        backgroundColour: null,
        lowestPrice: 0,
        highestPrice: 0,
        boxOffice: null,
        firstEventTime: null,
        lastEventTime: null,
        backgroundImage: "",
        maxImageWidth: 600,
        maxProductImageWidth: 550,
        notFound: false,
        order: Store.order,
        requested: {},
        option: {},
        options: {},
        availability: {},
        eventTimes: null,
        ticketTypes: null,
        products: null,
        product: null,
        event: null,
        eventTime: null,
        queued: false,
        logoImage: null
      }
    },
    
    async created() {
      this.$vuetify.theme.dark = false;
      if (this.$route.params.boxOfficePath == "SamRyder") {
        this.queued = true
        this.logoImage = "http://res.cloudinary.com/happytickets/image/upload/v1/X8nWSm171w/SAM_RYDER_PNG-_small_white_qfymrx"
        this.backgroundColour = "#0394b8FF"
        this.textColour = "#FFFFFFFF"
      } 
      if (this.$route.params.boxOfficePath == "OnlyThePoets") {
        this.queued = true
        this.logoImage = "https://res.cloudinary.com/happytickets/image/upload/v1/STQQ615wFI/new_happy_tickets_otp_u1kizz"
        this.backgroundColour = "#000000FF"
        this.textColour = "#FFFFFFFF"
      } 
      if (!this.queued) this.load();
    },

    mounted () {
      this.onResize()
    },

    methods: {

      queueUpdate(value) {
        this.queued = value
        if (!this.queued) this.load();
      },

      showProduct(product) {
        this.product = product;
        this.productDialog = true;
      },

      async load() {
        this.boxOffice = await Api.post(this, "BoxOffice", "read", {path: this.$route.params.boxOfficePath}, true);
        if (!this.boxOffice) {
          this.notFound = true;
          return;
        }
        this.logoImage = this.boxOffice.logoImage
        this.event = await Api.post(this, "Event", "read", {path: this.$route.params.eventPath, boxOfficeId: this.boxOffice.id}, true);
        if (!this.event) {
          this.notFound = true;
          return;
        }
        var content = this.boxOffice.name + " " + this.event.name +  " " + this.event.location
        this.meta = {
          title: this.event.name,
          meta: [
            { vmid: 'description', name: 'description', content: content },
            { name: "title", property: 'og:title', content: content },
            { name: "description", property: 'og:description', content: content },
            { property: 'twitter:title', content: content },
            { property: 'twitter:description', content: content },
          ]
        };
        if (this.event.facebookPixel) {
          console.log("logging facebook pixel")
          fbq('init', this.event.facebookPixel);
          fbq('track', 'PageView');
        }
        this.textColour = this.event.textColour;
        this.backgroundColour = this.event.backgroundColour;
        this.readImage();
        this.readAvailability();
        if (this.$route.params.eventTimeId) {
          this.eventTime = await Api.post(this, "EventTime", "read", {id: this.$route.params.eventTimeId}, true);
          if (this.eventTime) {
            this.firstEventTime = this.eventTime;
            this.lastEventTime = this.eventTime;
          }
        }
      },

      showTicketsDialog(eventTime) {
        this.eventTime = eventTime;
      },

      checkoutComplete() {
        this.ticketsDialog = false;
        this.checkout = false;
        this.eventTimeDialog = false;
        this.readAvailability();
      },

      async readAvailability() {
        await this.readTicketTypes();
        await this.readProducts();
        await this.readEventTimes();
        var availabilities = await Api.post(this, "Event", "availability", this.event);
        this.availability = {};
        for (var avail of availabilities) {
          var existing = this.availability[avail.eventTimeId];
          if (!existing) {
            existing = {};
          }
          existing[avail.ticketTypeId] = avail;
          this.availability[avail.eventTimeId] = existing;
        }
      },

      availableTickets(ticketType, eventTime, ignoreMyOrder) {
        if (!eventTime) {
          return 0;
        }
        var eventTimeAvailability = this.availability[eventTime.id];
        if (!eventTimeAvailability) {
          return 0;
        }
        if (ticketType == null) {
          var available = 0;
          for (var ticketTypeAvailability of Object.values(eventTimeAvailability)) {
            available += ticketTypeAvailability.availableTickets;
          }
          return available;
        } else {
          var ticketTypeAvailability = eventTimeAvailability[ticketType.id];
          if (!ticketTypeAvailability) {
            return 0;
          }
          if (!ignoreMyOrder) {
            let existing = this.findOrderItem(ticketType, eventTime);
            if (existing) {
              return ticketTypeAvailability.availableTickets - existing.quantity;
            }
          }
          return ticketTypeAvailability.availableTickets;
        }
      },

      async readImage() {
        if (!this.event.backgroundImage) {
          return;
        }
        var payload = {};
        payload.id = this.event.backgroundImage;
        var image = await Api.post(this, "Image", "read", payload);
        if (image) {
          this.backgroundImage = image.url;
        }
      },

      async readTicketTypes() {
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
        this.lowestPrice = 999999;
        this.highestPrice = 0;
        for (var ticketType of this.ticketTypes) {
          this.requested[ticketType.id]=1;
          if (ticketType.price < this.lowestPrice) {
            this.lowestPrice = ticketType.price;
          }
          if (ticketType.price > this.highestPrice) {
            this.highestPrice = ticketType.price;
          }
        }
        if (this.lowestPrice == 999999) {
          this.lowestPrice = 0;
        }
      },

      async readProducts() {
        this.products = await Api.post(this, "Product", "list", this.boxOffice);
        for (var product of this.products) {
          this.requested[product.id]=1;
          if (product.options) {
            this.options[product.id] = product.options.split(",")
            this.option[product.id] = this.options[product.id][0]
          }
        }
      },

      async readEventTimes() {
        var allEventTimes = await Api.post(this, "EventTime", "list", this.event);
        this.eventTimes = [];
        var now = Utils.dateNow();
        for (var eventTime of allEventTimes) {
          if (eventTime.end > now) {
            this.eventTimes.push(eventTime);
          }
        }
        if (this.eventTimes && this.eventTimes.length == 1) {
          this.eventTime = this.eventTimes[0];
        } else {
          this.eventTime = null;
        }
        if (this.eventTimes && this.eventTimes.length > 0) {
          this.firstEventTime = this.eventTimes[0];
          this.lastEventTime = this.eventTimes[this.eventTimes.length-1];
        }
      },

      plural(number) {
        if (number > 1) {
          return "s";
        }
      },

      async selectTicket (ticketType, quantity) {
        let existing = this.findOrderItem (ticketType, this.eventTime);
        if (existing != null) {
          existing.quantity += quantity;
          this.checkout = true;
          return;
        }
        let orderItem = {};
        orderItem.ticketType = ticketType;
        orderItem.ticketTypeId = ticketType.id;
        orderItem.event = this.event;
        orderItem.eventId = this.event.id;
        orderItem.eventTime = this.eventTime;
        orderItem.eventTimeId = this.eventTime.id;
        orderItem.quantity = quantity;
        orderItem.eventUrl = EventUtils.makeURL(this.boxOffice, this.event, this.eventTime);
        orderItem.price = ticketType.price + ticketType.bookingFee;
        this.order.items.push(orderItem);
        this.checkout = true;
      },

      async selectProduct (product, quantity, productOption) {
        let existing = this.findOrderProductItem(product, productOption);
        if (existing != null) {
          existing.quantity += quantity;
          this.checkout = true;
          return;
        }
        let orderItem = {};
        orderItem.product = product;
        orderItem.productId = product.id;
        orderItem.quantity = quantity;
        orderItem.price = product.price;
        orderItem.collectAddress = product.collectAddress;
        if (productOption) {
          orderItem.productOption = productOption;
        }
        this.order.items.push(orderItem);
        this.checkout = true;
      },

      findOrderItem (ticketType, eventTime) {
        if (this.order && this.order.items) {
          for (let item of this.order.items) {
            if (item.ticketTypeId == ticketType.id && item.eventTimeId == eventTime.id)  {
              return item;
            }
          }
        }
      },

      findOrderProductItem (product, option) {
        if (this.order && this.order.items) {
          for (let item of this.order.items) {
            if (item.productId == product.id && item.productOption == option)  {
              return item;
            }
          }
        }
      },

      numTickets(ticketType, eventTime) {
        var available = this.availableTickets(ticketType, eventTime);
        var max = ticketType.maximumPerOrder;
        if (!max || max > available) {
          max = available;
        }
        var array = [];
        for (var i=0; i<max; i++) {
          array[i]=i+1;
        }
        return array;
      },

      numProducts(product) {
        var available = product.quantity - product.sold;
        var max = product.maximumPerOrder;
        if (!max || max > available) {
          max = available;
        }
        var array = [];
        for (var i=0; i<max; i++) {
          array[i]=i+1;
        }
        return array;
      },

      productButtonLabel(quantity) {
        return "Buy " + quantity;
      },

      boxOfficeLink() {
        this.$router.push("/boxOffice/" + this.$route.params.boxOfficePath)
      },

      onResize () {
        this.maxImageWidth = (window.innerWidth * 0.9).toFixed(0);
        if (this.maxImageWidth > 600) {
          this.maxImageWidth = 600;
        }
        this.maxProductImageWidth = (window.innerWidth * 0.75).toFixed(0);
        if (this.maxProductImageWidth > 530) {
          this.maxProductImageWidth = 530;
        }
      }      
    },

    computed: {
      utils: function () {
        return Utils;
      },

      eventUtils: function () {
        return EventUtils;
      },

      collectCard() {
        if (this.cash) {
          return false;
        }
        if (this.totalCost == 0) {
          return false;
        }
        return true;
      },
      styles () {
        const paddingTop = 50
        const paddingBottom = 50
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        }
      },
      cssVars() {
        return {
          '--text-color': this.textColour,
          '--background-color': this.backgroundColour
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
  .v-card__text, .v-card__title 
    word-break: normal /* maybe !important  */
  .v-sheet.v-card
    border: thick solid
    color: var(--text-color)
    background-color: var(--background-color)
  div
    color: var(--text-color)
  .v-btn
    color: var(--text-color)
  .display-2
    color: var(--text-color)
  .v-text-field 
    color: var(--text-color)
</style>
